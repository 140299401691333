import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import React from "react";
import FormTable from "../forms/FormTable";

const EventProperties = (props) => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { queryEventProperties } = useApi();

    const nameColumn = {
        field: 'name',
        headerName: t('event-properties.name.label', 'Name'),
        flex: 1,
        editable: false,
        headerClassName: 'data-grid-header',
    };

    const descriptionColumn = {
        field: 'description',
        headerName: t('event-properties.description.label', 'Description'),
        flex: 1,
        editable: false,
        headerClassName: 'data-grid-header'
    };

    const activeColumn = {
        field: 'active',
        headerName: t('event-properties.active.label', 'Active'),
        renderCell: (params) => t(`event-properties.active.${params.value}`, "-"),
        flex: 1,
        editable: false,
        headerClassName: 'data-grid-header'
    };

    const [columns] = React.useState([
        nameColumn,
        descriptionColumn,
        activeColumn,
    ]);

    const [columnVisibility, setColumnVisibility] = React.useState({});

    const [sortModel, setSortModel] = React.useState(searchParams.get('sortField') && searchParams.get('searchOrder') ? [{
        field: searchParams.get('sortField'),
        sort: searchParams.get('sortOrder') ?? 'asc'
    }] : []);

    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');

    const [filterModel, setFilterModel] = React.useState({ items: []});

    const [paginationModel, setPaginationModel] = React.useState({
            pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : props.paginationModel.pageSize,
            page: searchParams.get('page') ? parseInt(searchParams.get('page')) : props.paginationModel.page,
        });

    const { data: eventProperties = null, isLoading } = queryEventProperties(
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 && sortModel[0].field ? sortModel[0].field : null,
        sortModel.length > 0 && sortModel[0].sort ? sortModel[0].sort : null,
        searchText,
        filterModel?.items ?? null,
    );

    const handleRowClick = (params) => {
        navigate(`/event-properties/${params.id}`);
    }

    const handleNewEventProperyClick = () => {
        navigate(`/event-properties/new`);
    }

    return (
        <FormTable
            createButtonLabel={t("event-properties.new-event-property", "New Event Property")}
            columns={columns}
            data={eventProperties}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            onSearchTextChange={setSearchText}
            onRowClick={handleRowClick}
            onNewClick={handleNewEventProperyClick}
            sortModel={sortModel}
            setSortModel={setSortModel}
            filterModel={filterModel}
            setFilterModel={setFilterModel}
            isLoading={isLoading}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            onScreenSizeChange={() => {}}
        />
    )

}

export default EventProperties;