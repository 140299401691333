import React from "react";
import "../../i18n/i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactDOMClient from "react-dom/client";
import { 
    createBrowserRouter, 
    createRoutesFromElements, 
    Route, 
    RouterProvider,  
} from "react-router-dom";
import { deDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Teams from "./Teams";
import Team from "./Team";

const theme = createTheme({
    palette: {
        primary: {
            main: '#4E80EE',
        },
        secondary: {
            main: '#E89D27',
        },
    },
    typography: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14,
    },
}, deDE, // x-data-grid translations
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
}
);

export default function TeamsContainer() {

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                <Route
                    exact
                    path={"teams/:id"}
                    element={<Team />}
                />
                <Route
                    exact
                    path={"teams"}
                    element={
                        <Teams
                            paginationModel={paginationModel}
                            setPaginationModel={setPaginationModel}
                        />
                    }
                />
            </Route>
        )
    )

    return <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
            </QueryClientProvider>
        </LocalizationProvider>
    </ThemeProvider>;
}

if (document.getElementById('teams-container')) {
    ReactDOMClient.createRoot(document.getElementById('teams-container')).render(<TeamsContainer />);
}
