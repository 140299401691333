import {Grid, Typography} from "@mui/material";
import UpcomingEvents from "./UpcomingEvents";
import ClientsWithoutEvents from "./ClientsWithoutEvents";
import NotPerformedEvents from "./NotPerformedEvents";
import NotConfirmedEvents from "./NotConfirmedEvents";

const Dashboard = () => {
    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];

    return (
        <Grid container>
            <Grid item xs={12} md={6} sx={{padding: 2}}>
                <UpcomingEvents/>
            </Grid>
            <Grid item xs={12} md={6} sx={{padding: 2}}>
                <ClientsWithoutEvents/>
            </Grid>
            <Grid item xs={12} md={6} sx={{padding: 2}}>
                <NotPerformedEvents/>
            </Grid>
            {/* <Grid item xs={12} md={6} sx={{padding: 2}}>
                <NotConfirmedEvents/>
            </Grid> */}
        </Grid>
    );
}

export default Dashboard;
