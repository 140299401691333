import { useTranslation } from "react-i18next";

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";

const TeamCalendarAutocompleteSelection = ({
    label,
    options,
    selectedOptions,
    handleSelection,
    renderTags = null,
    fixMargin = false,
    disableClearable = false,
}) => {
    const { t } = useTranslation();

    return (

        <Autocomplete
            noOptionsText={t('forms.no-options', 'No options')}
            multiple
            sx={{ 
                m: 1, 
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                '& .MuiFormControl-root': {
                    width: '95%',
                },
                marginLeft: fixMargin ? '0px' : undefined,
            }}
            options={options}
            value={selectedOptions}
            onChange={(_, newValue) => handleSelection(newValue)}
            getOptionLabel={(option) => option.i18nKeyLabel
                ? t(option.i18nKeyLabel)
                : (option.name ? option.name : option.label)
            }
            isOptionEqualToValue={(option, value) => value?.id
                ? option.id === value?.id
                : option.id === value      // if selection only stores ids
            }
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                />
            )}
            renderTags={renderTags}
            disableClearable={disableClearable}
        />
    );
};

export default TeamCalendarAutocompleteSelection;