import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const isSmallScreen = window.innerWidth < 600;

const ClientsViewStore = (set, get) => ({
    columnVisibility: {
        full_name: true,
        support_ranking: true,
        address: !isSmallScreen,
        employee: !isSmallScreen,
        phone: !isSmallScreen,
    },

    sortModels: [], // element: { field, sort }
    filterModelItems: [], // element: { field, operator: "contains", value }
    showArchive: false,

    paginationModel: {
        pageSize: 10,
        page: 0,
    },

    updateColumnVisibility: (inputColumnVisibility) => {
        const atLeastOneColumn = inputColumnVisibility?.full_name
            || inputColumnVisibility?.support_ranking
            || inputColumnVisibility?.address
            || inputColumnVisibility?.employee
            || inputColumnVisibility?.phone;

        set({
            columnVisibility: {
                full_name: (!atLeastOneColumn ? true : (inputColumnVisibility?.full_name ?? true)),
                support_ranking: (inputColumnVisibility?.support_ranking ?? true),
                address: (inputColumnVisibility?.address ?? true),
                employee: (inputColumnVisibility?.employee ?? true),
                phone: (inputColumnVisibility?.phone ?? true),
            }
        })
    },

    handleScreenSizeChange: (_) => {
        const currentVisibility = get().columnVisibility;

        const atLeastOneColumn = currentVisibility?.full_name
            || currentVisibility?.support_ranking
            || currentVisibility?.address
            || currentVisibility?.employee
            || currentVisibility?.phone;

        set({
            columnVisibility: {
                full_name: !atLeastOneColumn ? true : currentVisibility.full_name,
                support_ranking: currentVisibility.support_ranking,
                address: currentVisibility.address,
                employee: currentVisibility.employee,
                phone: currentVisibility.phone,
            }
        });
    },

    updateSortModels: (sortModels) => {
        set({
            sortModels: [...sortModels],
        });
    },

    updateFilterModelItems: (filterModel) => {
        set({
            filterModelItems: [...filterModel.items],
        });
    },

    updateArchive: (checked) => {
        set({
            showArchive: checked,
        });
    },

    updatePaginationModel: (paginationModel) => {
        set({
            paginationModel: {
                pageSize: parseInt(paginationModel?.pageSize) ?? 10,
                page: parseInt(paginationModel?.page) ?? 0,
            }
        })
    }
    
})

export const useClientsViewStore = create(
    persist(
        ClientsViewStore,
        {
            name: 'clients-view-storage',
        }
    )
);
