import React, { useEffect, useRef, useState } from "react";

import useApi from "../../hooks/useApi";
import useConfig from "../../hooks/useConfig";
import useUser from "../../hooks/useUser";
import { Trans } from "react-i18next";
import { useCalendarViewStore } from "../../store/CalendarViewStore";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TeamCalendarAutocompleteSelection from "./TeamCalendarAutocompleteSelection";
import { Chip } from "@mui/material";

const TeamCalendarMemberFilter = ({ canTeamEvents }) => {
    const [isTeamFilterOpen, setIsTeamFilterOpen] = useState(false);

    const handleOpenTeamFilterClick = () => {
        setIsTeamFilterOpen(!isTeamFilterOpen);
    };

    const { queryEventEmployees } = useApi();
    const { eventColors } = useConfig();
    const { getEmployeeId } = useUser();
    const employeeId = getEmployeeId();

    const isSelectedInitialized = useCalendarViewStore(state => state.isSelectedInitialized);
    const selected = useCalendarViewStore(state => state.selected);

    const initializeSelected = useCalendarViewStore(state => state.initializeSelected);
    const updateSelected = useCalendarViewStore(state => state.updateSelected);
    const handleSelection = useCalendarViewStore(state => state.handleSelection);

    const { data: teamMembers = [] } = queryEventEmployees(
        employeeId,
        (data) => {
            if (!isSelectedInitialized) {
                selectAll(data);
            }
        }
    );

    const additionalButtonsCount = canTeamEvents ? 2 : 3;
    const isAllSelected = selected?.length === (teamMembers.length + additionalButtonsCount);    // +2 for handle not assigned and public // +3 for Others
    const isSomeSelected = selected?.length > 0 && !isAllSelected;
    const isNoneSelected = !selected || selected?.length === 0;

    const compareTeamMember = (teamMember1, teamMember2) => {
        if (teamMember1.last_name < teamMember2.last_name) {
            return -1;
        }

        if (teamMember1.last_name > teamMember2.last_name) {
            return 1;
        }

        if (teamMember1.first_name < teamMember2.first_name) {
            return -1;
        }

        if (teamMember1.first_name > teamMember2.first_name) {
            return 1;
        }

        return 0;
    }

    const selectAll = (teamMembers) => {
        let selections = [];
        selections.push(-1);
        selections.push(-3);

        for (const employee of teamMembers) {
            selections.push(employee.id);
        }

        if (!canTeamEvents) {
            selections.push(-2);
        }

        if (!isSelectedInitialized) {
            initializeSelected(selections);
        } else {
            updateSelected(selections);
        }
    }

    const selectNone = () => {
        let selections = [];
        updateSelected(selections);
    }

    const deselectTeamMembers = () => {
        let newSelection = selected.filter(selection => selection < 0);
        updateSelected(newSelection);
    }

    const handleVisibilityClick = () => {
        if (isAllSelected) {
            selectNone();
            return;
        }

        selectAll(teamMembers);
    }

    const onClickTeamMember = (teamMemberId) => {
        handleSelection(teamMemberId);
    };

    const options = teamMembers
        .sort(compareTeamMember)
        .map(teamMember => ({
            id: teamMember.id,
            color: teamMember.employee_color,
            label: `${teamMember.last_name} ${teamMember.first_name}`,
        }));

    const selection = selected
        .map(selectedId => options.find(option => option.id === selectedId))
        .filter(selected => !!selected)
        .sort((a,b) => (a.label > b.label) 
            ? 1 
            : ((b.label > a.label) ? -1 : 0)
        );

    return (
        <>
            <ListItem sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <ListItemButton onClick={handleVisibilityClick} sx={{ position: 'absolute', left: 0, marginLeft: '8px' }}>
                    {isAllSelected && <VisibilityIcon />}
                    {isSomeSelected && <VisibilityIcon sx={{ color: 'grey' }} />}
                    {isNoneSelected && <VisibilityOffIcon />}
                </ListItemButton>
                <ListItemText sx={{ textAlign: 'center', flex: 1 }} primary="Kalender" />
                <ListItemButton onClick={handleOpenTeamFilterClick} sx={{ position: 'absolute', right: 0, marginRight: '8px' }}>
                    {isTeamFilterOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={isTeamFilterOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        key={'team-member-public'}
                        onClick={() => onClickTeamMember(-3)}
                    >
                        {selected.includes(-3) ?
                            <div className="rounded-sm w-4 h-4 mr-2" style={{ backgroundColor: eventColors.darkGrey }}></div>
                            :
                            <div className="ring-1 ring-black ring-opacity-50 rounded-sm w-4 h-4 mr-2"></div>
                        }
                        <Trans i18nKey="team-calendar.public">Public Events</Trans>
                    </ListItemButton>
                    <ListItemButton
                        key={'team-member-none'}
                        onClick={() => onClickTeamMember(-1)}
                    >
                        {selected.includes(-1) ?
                            <div className="rounded-sm w-4 h-4 mr-2" style={{ backgroundColor: eventColors.notAssigned }}></div>
                            :
                            <div className="ring-1 ring-black ring-opacity-50 rounded-sm w-4 h-4 mr-2"></div>
                        }
                        <Trans i18nKey="team-calendar.not-assigned">Not assigned</Trans>
                    </ListItemButton>
                    {!canTeamEvents &&
                        <ListItemButton
                            key={'team-member-other'}
                            onClick={() => onClickTeamMember(-2)}
                        >
                            {selected.includes(-2) ?
                                <div className="rounded-sm w-4 h-4 mr-2" style={{ backgroundColor: eventColors.notAssigned }}></div>
                                :
                                <div className="ring-1 ring-black ring-opacity-50 rounded-sm w-4 h-4 mr-2"></div>
                            }
                            <Trans i18nKey="team-calendar.other">Other</Trans>
                        </ListItemButton>
                    }
                </List>
                <TeamCalendarAutocompleteSelection
                    fixMargin
                    label={''}
                    options={options}
                    selectedOptions={selection}
                    handleSelection={(value) => value.length === 0
                        ? deselectTeamMembers()
                        : onClickTeamMember(value.at(-1).id)}
                    renderTags={(value, _) =>
                        value.map((option, _) => (
                            <Chip
                                key={option.id}
                                variant="outlined"
                                label={option.label}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    background: option.color,
                                    color: 'white',
                                    '& .MuiChip-label': {
                                        flexGrow: 1, // Makes the label take up remaining space
                                        textAlign: 'center', // Aligns the label to the left
                                    },
                                    '& .MuiChip-deleteIcon': {
                                        color: 'white',
                                        marginRight: '8px', // Optional: Add some space from the edge
                                    },
                                }}
                                onDelete={() => onClickTeamMember(option.id)}
                                onMouseDown={(event) => event.stopPropagation()}
                            />
                        ))
                    }
                    disableClearable
                />
            </Collapse>
        </>
    );
};

export default TeamCalendarMemberFilter;
