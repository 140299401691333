import useApi from "../../hooks/useApi";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React from "react";
import FormTable from "../forms/FormTable";

const Teams = (props) => {
    
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { queryTeams } = useApi();

    const getColumnValueForList = (list) => {
        let values = list;

        if (values.length > 3) {
            values = list.slice(0, 2);
            values.push('+ ' + (list.length - 2));
        }

        const concatenation = values.join('<br>');
        return <div dangerouslySetInnerHTML={{ __html: concatenation }} />;
    }

    const nameColumn = {
        field: 'name',
        headerName: t('teams.name.label', 'Name'),
        renderCell: (params) => {
            const teamId = params?.row?.id ?? null;
            const parentId = params?.row?.parent?.id ?? null;
            const parentName = parentId !== teamId ? params?.row?.parent?.name : '-';

            return (
                <div>
                    <b>{params?.value ?? '-'}</b><br />
                    ({parentName})
                </div>
            );
        },
        flex: 1,
        editable: false,
        headerClassName: 'data-grid-header',
    };

    const leadersColumn = {
        field: 'leaders',
        headerName: t('teams.leader.label', 'Leader'),
        renderCell: (params) => {
            const leaders = params?.value ?? null;

            if (!leaders || leaders.length === 0) {
                return '-'
            }

            const names = leaders
                .filter(leader => leader && leader.person)
                .map(leader => `${leader.person.last_name} ${leader.person.first_name}`);
            return getColumnValueForList(names);
        },
        flex: 1,
        editable: false,
        headerClassName: 'data-grid-header',
    };

    const addressColumn = {
        field: 'address',
        headerName: t('teams.address.label', 'Address'),
        renderCell: (params) => {
            if (!params || !params.value) {
                return '-';
            }

            const street = params.value.street ?? '';
            const streetNumber = params.value.street_number ?? '';
            const zip = params.value.zip ?? '';
            const city = params.value.city ?? '';
            return `${street} ${streetNumber}, ${zip} ${city}`;
        },
        flex: 1,
        editable: false,
        headerClassName: 'data-grid-header',
    };

    const memberCountColumn = {
        field: 'member_count',
        headerName: t('teams.member.label', 'Member'),
        flex: 1,
        editable: false,
        headerClassName: 'data-grid-header'
    };

    const [columns] = React.useState([
        nameColumn,
        leadersColumn,
        addressColumn,
        memberCountColumn,
    ]);


    const [columnVisibility, setColumnVisibility] = React.useState({ });


    const [sortModel, setSortModel] = React.useState(searchParams.get('sortField') && searchParams.get('searchOrder') ? [{
        field: searchParams.get('sortField'),
        sort: searchParams.get('sortOrder') ?? 'asc'
    }] : []);


    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');


    const [filterModel, setFilterModel] = React.useState({ items: []});
    

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : props.paginationModel.pageSize,
        page: searchParams.get('page') ? parseInt(searchParams.get('page')) : props.paginationModel.page,
    });


    const { data: teams = null, isLoading } = queryTeams(
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 && sortModel[0].field ? sortModel[0].field : null,
        sortModel.length > 0 && sortModel[0].sort ? sortModel[0].sort : null,
        searchText,
        filterModel?.items ?? null,
    );


    const handleRowClick = (params) => {
        navigate(`/teams/${params.id}`);
    }

    const handleNewTeamClick = () => {
        navigate(`/teams/new`);
    }

    const handleScreenSizeChange = (_) => {
        
    }


    return (
        <FormTable
            createButtonLabel={t("teams.create-title", "New Team")}
            columns={columns}
            data={teams}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            onSearchTextChange={setSearchText}
            onRowClick={handleRowClick}
            onNewClick={handleNewTeamClick}
            sortModel={sortModel}
            setSortModel={setSortModel}
            filterModel={filterModel}
            setFilterModel={setFilterModel}
            onScreenSizeChange={handleScreenSizeChange}
            isLoading={isLoading}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            rowHeight={'auto'}
        />
    );
}

export default Teams;
