import {
    Box, Breadcrumbs,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Link, Paper,
    Stack,
    TextField, Typography
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import useApi from "../../hooks/useApi";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import FormSelect from "../forms/FormSelect";
import useConfig from "../../hooks/useConfig";
import useRouting from "../../hooks/useRouting";
//import "./EventProperty.css";

const EventProperty = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const { queryEventProperty, saveOrUpdateEventProperty } = useApi();
    const { goToEventProperty, goToEventProperties } = useRouting();
    const { data: eventProperty = null, isLoading, refetch } = queryEventProperty(id);

    const [editable, setEditable] = useState(id === 'new');
    const [disabled, setDisabled] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(1);
    const [errName, setErrName] = useState('');
    const [errDescription, setErrDescription] = useState('');
    const [showValidation, setShowValidation] = useState(false);

    const navigate = useNavigate();

    const { gridItemSxLabel, gridItemSxValue, gridItemSxValueEditable } = useConfig();
    const currentGridItemSxValue = editable ? gridItemSxValue : gridItemSxValueEditable;

    const init = () => {
        setName(eventProperty.name);
        setDescription(eventProperty.description);
        setActive(eventProperty?.active);
    }

    useEffect(() => {
        if (eventProperty && eventProperty.id) {
            init();
        }
    }, [eventProperty]);

    const handleNameChange = (event) => {
        setName(event.target.value);
        setErrName('');
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        setErrDescription('');
    }

    const handleActiveChange = (event) => {
        setActive(event.target.value);
    }

    const handleCancel = () => {
        setEditable(false);
        if (eventProperty && eventProperty.id) {
            init();
        } else {
            goToEventProperties();
            setName('');
            setDescription('');
            setActive(0);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSave = () => {
        setDisabled(true);

        let data = {};
        if (id) {
            data.id = id;
        }

        data.name = name;
        data.description = description;
        data.active = active

        saveOrUpdateEventProperty(data).then((response) => {

            setDisabled(false);
            setEditable(false);
            if (data.id === 'new' && response.data.id) {
                goToEventProperty(response.data.id);
            } else {
                refetch();
            }
        }).catch((error) => {
            setDisabled(false);
            if (error.response?.status === 422 || error.response?.status === 500) {
                const errors = error.response.data?.errors;
                if (errors) {
                    setErrName(errors.name ?? '');
                    setErrDescription(errors.description ?? '');
                }
            }
        });
    };

    return (
        <Box sx={{ padding: 2 }}>
            {isLoading ?
                <Stack sx={{ flexGrow: 1, padding: 2 }} alignItems="center">
                    <CircularProgress />
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                        <NavLink underline="hover" color="inherit" to="/event-properties">
                            <Trans i18nKey="event-properties.title">Event Properties</Trans>
                        </NavLink>
                        <Typography color="text.primary">{eventProperty?.name}</Typography>
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{ marginBottom: 2, padding: 2, maxWidth: 1024 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{ paddingLeft: 2, display: 'flex', justifyContent: 'flex-start' }}>
                                    {editable && eventProperty?.id &&
                                        <Typography variant="h5">
                                            <Trans i18nKey={"event-properties.edit-title"}>Edit Event Property</Trans>
                                        </Typography>
                                    }
                                    {editable && !eventProperty?.id &&
                                        <Typography variant="h5">
                                            <Trans i18nKey={"event-properties.create-title"}>Create Event Property</Trans>
                                        </Typography>
                                    }
                                    {!editable &&
                                        <Typography variant="h5">{eventProperty?.name}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {!editable &&
                                        <Button
                                            sx={{ textTransform: 'none' }}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => setEditable(!editable)}
                                        >
                                            <Trans i18nKey="event-properties.edit">Edit</Trans>
                                            {/*<EditOutlinedIcon />*/}
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={0} sx={{ padding: 2, maxWidth: 1024 }}>
                        <Grid container alignItems="center">

                            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                <Trans i18nKey={"event-properties.name.label"}>Name</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="name"
                                        fullWidth
                                        /*multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={name}
                                        placeholder={t('event-properties.name.placeholder', 'Enter Name')}
                                        InputProps={{
                                            style: {
                                                fontSize: 14, border: 'none',
                                                outline: 'none',
                                            }
                                        }}
                                        sx={{ fontSize: 14, backgroundColor: '#fff' }}
                                        onChange={handleNameChange}
                                        error={!!errName}
                                        helperText={errName}
                                    />
                                    :
                                    eventProperty?.name
                                }
                            </Grid>


                            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                <Trans i18nKey="event-properties.description.label">Description</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="description"
                                        fullWidth
                                        /*multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={description}
                                        placeholder={t('event-properties.description.placeholder', 'Enter description')}
                                        InputProps={{
                                            style: {
                                                fontSize: 14, border: 'none',
                                                outline: 'none',
                                            }
                                        }}
                                        sx={{ fontSize: 14, backgroundColor: '#fff' }}
                                        onChange={handleDescriptionChange}
                                        error={!!errDescription}
                                        helperText={errDescription}
                                    />
                                    :
                                    eventProperty?.description
                                }
                            </Grid>

                            <FormSelect
                                i18nKeyLabel="event-properties.active.label"
                                editable={editable}
                                valueText={t('event-properties.active.' + active)}
                                value={active}
                                onChange={handleActiveChange}
                                disabled={disabled}
                                showValidation={showValidation}
                                required={false}
                                displayEmpty={true}>
                                <MenuItem sx={{ fontSize: 14 }} value={1}>
                                    <Trans i18nKey="event-properties.active.1"></Trans>
                                </MenuItem>
                                <MenuItem sx={{ fontSize: 14 }} value={0}>
                                    <Trans i18nKey="event-properties.active.0"></Trans>
                                </MenuItem>
                            </FormSelect>


                        </Grid>

                        {editable && (
                            <Grid container maxWidth="1024px">
                                <Grid item xs={12}>
                                    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                marginRight: 2,
                                                textTransform: 'none', background: '#fff'
                                            }}
                                            onClick={handleCancel}
                                        >
                                            <Trans i18nKey="event-properties.cancel">Cancel</Trans>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disableElevation
                                            sx={{ textTransform: 'none' }}
                                            onClick={handleSave}
                                        >
                                            <Trans i18nKey="event-properties.save">Save</Trans>
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                </>
            }
        </Box>
    )
};

export default EventProperty;