import React from "react";
import "../../i18n/i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import EventProperties from "./EventProperties";
import { deDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from "@mui/material";
import EventProperty from "./EventProperty";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";

const theme = createTheme({
    palette: {
        primary: {
            main: '#4E80EE',
        },
        secondary: {
            main: '#E89D27',
        },
    },
    typography: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14,
    },
}, deDE, // x-data-grid translations
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
}
);

export default function EventPropertiesContainer() {

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });

    return <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route 
                            exact 
                            path={"event-properties/:id"} 
                            element={<EventProperty />} 
                        />
                        <Route 
                            exact 
                            path={"event-properties"} 
                            element={
                                <EventProperties 
                                    paginationModel={paginationModel} 
                                    setPaginationModel={setPaginationModel} 
                                />
                            } 
                        />
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </LocalizationProvider>
    </ThemeProvider>;
}

if (document.getElementById('event-properties-container')) {
    ReactDOMClient.createRoot(document.getElementById('event-properties-container')).render(<EventPropertiesContainer />);
}
