import useApi from "../../hooks/useApi";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useRouting from "../../hooks/useRouting";
import ImportModal from "../ImportModal";
import { useClientsViewStore } from '../../store/ClientsViewStore';
import FormTable from '../forms/FormTable';
import { getGridStringOperators } from "@mui/x-data-grid";
import moment from "moment";

const Clients = (props) => {

    const [searchParams] = useSearchParams();
    const routing = useRouting();
    const { t } = useTranslation();
    const { queryClients, exportClients, convertClients, importClients } = useApi();

    const stringOperators = getGridStringOperators().filter((operator => ['contains'].includes(operator.value)));

    const normalizePhoneNumber = (phone) => {
        const phoneNumberWithLeadingZero = phone?.phone_number ?? '';

        let countryCode = phone?.country_code ?? '';
        countryCode = countryCode == 'DE' ? '49' : '';

        if (!phoneNumberWithLeadingZero || !countryCode) {
            return '';
        }

        const formattedPhoneNumber = phoneNumberWithLeadingZero.startsWith('0')
            ? phoneNumberWithLeadingZero.slice(1)
            : phoneNumberWithLeadingZero;

        return `${countryCode}${formattedPhoneNumber}`;
    }

    const [columns] = useState([
        {
            field: 'full_name',
            headerName: t('clients.full-name.label', 'Name'),
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.person.last_name} ${params.row.person.first_name}`;
            },
            renderCell: (params) => {
                return (<b>{`${params.row.person.last_name} ${params.row.person.first_name}`}</b>);
            },
            editable: false,
            headerClassName: 'data-grid-header',
            filterOperators: stringOperators,
        },
        {
            field: 'address',
            headerName: t('clients.address.label', 'Address'),
            flex: 1,
            valueGetter: (params) => {
                const address = params.row.person.address;

                return address
                    ? `${address.street} ${address.street_number}, ${address.zip} ${address.city}`
                    : '';
            },
            editable: false,
            headerClassName: 'data-grid-header',
            filterOperators: stringOperators,
        },
        {
            field: 'phone',
            headerName: t('clients.phone.label', 'Phone'),
            flex: 1,
            valueGetter: (params) => {
                const phone = params.row.person.phone_home;
                return normalizePhoneNumber(phone) ? phone.phone_number : '';
            },
            renderCell: (params) => {
                const phone = params.row.person.phone_home;
                const normalizedPhoneNumber = normalizePhoneNumber(phone);

                if (!normalizedPhoneNumber) {
                    return '';
                }

                return (
                    <a href={`tel:+${normalizedPhoneNumber}`} style={{ color: 'blue', textDecoration: 'none' }}>
                        {phone.phone_number} {/* Display the phone number with leading 0 */}
                    </a>
                );
            },
            editable: false,
            headerClassName: 'data-grid-header',
            filterOperators: stringOperators,
        },
        {
            field: 'employee',
            headerName: t('clients.employee.label', 'Employee'),
            flex: 1,
            valueGetter: (params) => {
                return params.row.employees && params.row.employees[0]
                    ? `${params.row.employees[0].person.last_name} ${params.row.employees[0].person.first_name}`
                    : '';
            },
            editable: false,
            headerClassName: 'data-grid-header',
            filterOperators: stringOperators,
        },
        {
            field: 'support_ranking',
            headerName: t('clients.support-ranking.label', 'Support Ranking'),
            flex: 1,
            filterable: false,
            valueGetter: (params) => {
                switch (params?.row?.support_ranking?.ranking) {
                    case 'primary':
                        return t('clients.support-ranking.primary', 'primary');
                    case 'secondary':
                        return t('clients.support-ranking.secondary', 'secondary');
                    case 'temporary':
                        const startsAt = moment(params.row.support_ranking.starts_at).format('DD.MM.YYYY');
                        return t('clients.support-ranking.temporary', 'temporary')
                            + ` (${t('clients.support-ranking.since', 'since')} ${startsAt})`;
                    default:
                        return t('clients.support-ranking.none', 'none');
                }
            }
        }
    ]);

    const [searchText, setSearchText] = useState(searchParams.get('search') ?? '');

    const sortModel = useClientsViewStore(state => state.sortModels);
    const updateSortModels = useClientsViewStore(state => state.updateSortModels);

    const filterModelItems = useClientsViewStore(state => state.filterModelItems);
    const updateFilterModelItems = useClientsViewStore(state => state.updateFilterModelItems);

    const paginationModel = useClientsViewStore(state => state.paginationModel);
    const updatePaginationModel = useClientsViewStore(state => state.updatePaginationModel);

    const showArchive = useClientsViewStore(state => state.showArchive);
    const updateArchive = useClientsViewStore(state => state.updateArchive);

    const { data: clients = null, isLoading, refetch: clientsRefetch } = queryClients(
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 ? sortModel[0].field : null,
        sortModel.length > 0 ? sortModel[0].sort : null,
        searchText,
        filterModelItems,
        showArchive,
    );

    const [isImportModalOpen, setIsImportModalOpen] = useState(false);

    const columnVisibility = useClientsViewStore(state => state.columnVisibility);
    const updateColumnVisibility = useClientsViewStore(state => state.updateColumnVisibility);
    const handleScreenSizeChange = useClientsViewStore(state => state.handleScreenSizeChange);

    const handleRowClick = (params) => {
        routing.goToClient(params.id);
    }

    const handleNewClientClick = () => {
        routing.goToClient('new');
    }

    const handleExportClientsClick = () => {
        exportClients();
    }


    const handleImportClientsClick = () => {
        setIsImportModalOpen(true);
    }


    const handleClientsConvert = (file, onFinish) => {
        convertClients(file, onFinish);
    }


    const handleClientsImport = (file, onFinish, onSuccess) => {
        importClients(file, onFinish, onSuccess, clientsRefetch);
    }

    return (
        <>
            <FormTable
                createButtonLabel={t("clients.create-title", "New Client")}
                columns={columns}
                data={clients}
                paginationModel={paginationModel}
                setPaginationModel={updatePaginationModel}
                onSearchTextChange={setSearchText}
                onRowClick={handleRowClick}
                onNewClick={handleNewClientClick}
                sortModel={sortModel}
                setSortModel={updateSortModels}
                filterModel={{ items: filterModelItems }}
                setFilterModel={updateFilterModelItems}
                isLoading={isLoading}
                columnVisibility={columnVisibility}
                setColumnVisibility={updateColumnVisibility}
                createPermissions={["clients.create", "clients"]}
                onExportClick={handleExportClientsClick}
                exportButtonLabel={t("clients.export-clients", "Export Clients")}
                exportPermissions={["clients.export", "clients"]}
                onImportClick={handleImportClientsClick}
                importButtonLabel={t("clients.import-clients", "Import Clients")}
                importPermissions={["clients.import", "clients"]}
                onScreenSizeChange={handleScreenSizeChange}
                archiveLabel={t("clients.show-archive", "Show archived clients")}
                showArchive={showArchive}
                onArchiveClick={(event) => updateArchive(event.target.checked)}
                getRowClassName={(params) => {
                    if (!params.row.contract_end) {
                        return 'active-row';
                    }

                    const contractEnd = moment(params.row.contract_end);
                    const today = moment().startOf('day');
                    const dayDifference = contractEnd.diff(today, 'days');
                    const contractEndExpired = dayDifference <= 0;
                    return contractEndExpired ? "inactive-row" : "active-row"
                }}
            />
            <ImportModal
                isOpen={isImportModalOpen}
                onClose={() => setIsImportModalOpen(false)}
                title={t("clients.import-clients", "Import Clients")}
                handleConvert={handleClientsConvert}
                handleImport={handleClientsImport}
            />
        </>
    );
}

export default Clients;
