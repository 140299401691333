import { Trans } from "react-i18next";

const EventServiceSelectionModal = ({
    editable,
    serviceId,
    setServiceId,
    services,
    serviceIdError,
}) => {


    const sortedServices = services?.length  ?
         services.sort(function (service1, service2) {
        if (service1.name < service2.name) {
            return -1;
        }
        if (service1.name > service2.name) {
            return 1;
        }
        return 0;
    }) : [];


    const getService = (id) => {
        return services?.find((service) => service.id === parseInt(id)) || null;
    }


    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">

            <label
                htmlFor="service"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.service">Service</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable ?
                    <div className="sm:grid-row-2">
                        <select
                            id="service"
                            name="service"
                            value={serviceId}
                            onChange={(e) => setServiceId(e.target.value)}
                            className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none sm:text-sm ${serviceIdError ? "border-red-500" : "border-gray-300"}`}
                        >
                            <option disabled value={-1}>
                                <Trans i18nKey="team-calendar.event-modal.select-service">select a service</Trans>
                            </option>
                            {sortedServices && sortedServices.map(service => (
                                <option key={service.id}
                                    value={service.id}>{service.name}</option>
                            ))}
                        </select>

                        {serviceIdError &&
                            <p className="mt-2 text-sm text-red-600">
                                <Trans i18nKey="team-calendar.event-modal.error.select-service">
                                    Please select a service.
                                </Trans>
                            </p>
                        }
                    </div>
                    :
                    <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                        {services && serviceId && parseInt(serviceId) !== -1 ? getService(serviceId)?.name || '-' : '-'}
                    </div>
                }
            </div>
        </div>
    )
};

export default EventServiceSelectionModal;
