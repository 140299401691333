import { useTranslation } from "react-i18next";
import useConfig from "../../hooks/useConfig";
import { useCalendarViewStore } from "../../store/CalendarViewStore";
import TooltipContent from "./TooltipContent";
import HtmlTooltip from "./HtmlTooltip";


const useTeamCalendarEventContent = (employeeId) => {


    const { t } = useTranslation();
    const { eventColors } = useConfig();
    const viewType = useCalendarViewStore(state => state.viewType);

    const getStatusColor = (event) => {
        if (event.event_type_id !== 'CLIENT_APPOINTMENT' && event.event_type_id !== 'INTERNAL') {
            return null;
        }

        switch (event.status) {
            case 'NOT_PERFORMED':
                return event.employee_ids.includes(employeeId)
                    ? eventColors.notPerformed
                    : eventColors.notPerformedLight;
            case 'PERFORMED':
                return event.employee_ids.includes(employeeId)
                    ? eventColors.performed
                    : eventColors.performedLight;
            case 'CONFIRMED':
                return eventColors.confirmed;
            default:
                return eventColors.notPerformedLight;
        }
    }

    const getEventColor = (eventInfo) => {
        const isEventEmployeeListEmpty = eventInfo.event.extendedProps.employee_ids.length === 0;

        switch (eventInfo.event.extendedProps.event_type_id) {
            case 'CLIENT_APPOINTMENT':
                return !isEventEmployeeListEmpty
                    ? eventInfo.event.extendedProps.eventColor
                    : eventColors.notAssigned;
            case 'HOLIDAY':
                return !isEventEmployeeListEmpty
                    ? eventInfo.event.extendedProps.eventColor
                    : eventColors.internal;
            case 'SICK':
                return !isEventEmployeeListEmpty
                    ? eventInfo.event.extendedProps.eventColor
                    : eventColors.internal;
            case 'PUBLIC':
            case 'INTERNAL':
            default:
                return eventColors.internal;
        }
    }


    const renderEventContent = (eventInfo) => {
        const statusColor = getStatusColor(eventInfo.event.extendedProps);
        const backgroundColor = getEventColor(eventInfo);
        const employeeColors = eventInfo.event.extendedProps.employee_colors;
        let title = eventInfo.event.title;
        const isListView = viewType === 'list';
        const isSingleDayView = viewType === 'single-day';
        const isMonthView = viewType === 'month';
        const isAllDayEvent = eventInfo.event.allDay;
        if (isAllDayEvent) 
        {
            const employeeLastName = eventInfo.event.extendedProps.employees[0]?.person?.last_name ?? '';
            const employeeFirstName = eventInfo.event.extendedProps.employees[0]?.person?.first_name ?? '';
            title = title + ' ' + employeeLastName + ' ' + employeeFirstName;
        }
        const isClientAppointment = eventInfo.event.extendedProps.event_type_id === 'CLIENT_APPOINTMENT';
        const isInternal = eventInfo.event.extendedProps.event_type_id === 'INTERNAL';
        const isPublic = eventInfo.event.extendedProps.event_type_id === 'PUBLIC';
        
        const textColor = isInternal || isPublic ? eventColors.darkerGrey : '#ffffff';

        const eventStart = t('team-calendar.event.time', { date: eventInfo.event.start });
        const eventEnd = t('team-calendar.event.time', { date: eventInfo.event.end });

        const location = eventInfo.event.extendedProps.location;

        return (
            <HtmlTooltip title={<TooltipContent
                event={eventInfo.event}
                eventInfo={eventInfo.event.extendedProps}
            />}>
                <div
                    className={`bg-white rounded border border-gray-400 ${!isAllDayEvent ? 'h-full p-1' : 'h-5 pl-1'} max-w-full relative cursor-pointer text-xs text-gray-400 overflow-hidden`}
                    style={{ backgroundColor: backgroundColor }}
                >
                    {/* Dynamically generated color bars for each user */}
                    {isInternal &&
                        <div className="absolute top-0 right-0 h-full w-2.5 flex flex-col">
                            {employeeColors.map((color, index) => (
                                <div
                                    key={index}
                                    style={{
                                        backgroundColor: color, // Apply user color
                                        height: `${100 / employeeColors.length}%`, // Divide height equally
                                    }}
                                />
                            ))}
                        </div>
                    }
                    {(isClientAppointment || isInternal) &&
                        <div 
                            className="absolute right-0 w-4 h-4" 
                            style={{ marginRight: isInternal ? '8px' : undefined}}
                        >
                            <div
                                className="h-3 w-3 border rounded-full cursor-pointer"
                                style={{ backgroundColor: statusColor }}
                            />
                        </div>
                    }
                    <div className={`${isInternal ? 'pr-3' : ''} xs:block`}
                        style={{color: textColor,
                                backgroundColor: backgroundColor,
                                display: (isSingleDayView || isListView ||isMonthView) ? 'block' : 'hidden'
                                }}
                    >

                        {/* time stamp */}
                        {isSingleDayView && !isAllDayEvent &&
                            <div>{eventStart} - {eventEnd}</div>
                        }

                        {!(isSingleDayView || isListView) && !isAllDayEvent &&
                            <div className="hidden sm:block">{eventStart}</div>
                        }

                        {/* Title and Location Container */}

                        <div className="flex flex-col max-h-full">
                            {title &&
                                <div className={`flex-1 break-all ${!isAllDayEvent ? 'mt-1' : ''}`}
                                    style={{
                                        display: '-webkit-box',          // Enables the use of webkit line clamping
                                        WebkitLineClamp: '5',          // Large number to fill up all height
                                        WebkitBoxOrient: 'vertical',     // Vertical orientation for line clamping
                                        maxHeight: '100%',               // Restricts to parent's height
                                        overflow: 'hidden',              // Truncates at max height
                                        textOverflow: 'ellipsis',        // Adds ellipses
                                    }}>
                                    {title}
                                </div>
                            }

                            {location && (
                                <div className="flex-1 break-all mt-1"
                                    style={{
                                        display: '-webkit-box',          // Enables the use of webkit line clamping
                                        WebkitLineClamp: '5',          // Large number to fill up all height
                                        WebkitBoxOrient: 'vertical',     // Vertical orientation for line clamping
                                        maxHeight: '100%',               // Restricts to parent's height
                                        overflow: 'hidden',              // Truncates at max height
                                        textOverflow: 'ellipsis',        // Adds ellipses
                                    }}>
                                    {location}
                                </div>
                            )}
                        </div>
                        
                    </div>
                </div>
            </HtmlTooltip>
        );
    };


    return {
        renderEventContent,
    }


}


export default useTeamCalendarEventContent;
