import { useEffect, useState } from "react";

import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import AccessControl from "../access/AccessControl";
import useApi from "../../hooks/useApi";
import { NavLink, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import FormLeavingGuard from "../forms/FormLeavingGuard";
import FormAutocomplete from "../forms/FormAutocomplete";
import useConfig from "../../hooks/useConfig";
import useRouting from "../../hooks/useRouting";

const Team = () => {

    const { id } = useParams();
    const { goToTeams, goToTeam } = useRouting();
    const { t } = useTranslation();
    const { queryTeam, queryTeamsNames, queryEmployeeNames, saveOrUpdateTeam } = useApi();


    const { gridItemSxLabel, gridItemSxValue, gridItemSxValueEditable } = useConfig();
    const currentGridItemSxValue = editable ? gridItemSxValue : gridItemSxValueEditable;


    const { 
        data: team = null, 
        isLoading: isLoadingTeam, 
        refetch: refetchTeam 
    } = queryTeam(id);
    const { 
        data: teamsNames = null, 
        isLoading: isLoadingTeamsNames, 
        refetch: refetchTeamsNames 
    } = queryTeamsNames();
    const { 
        data: employeesNames = null, 
        isLoading: isLoadingEmployeesNames, 
        refetch: refetchEmployeesNames 
    } = queryEmployeeNames();

    const [editable, setEditable] = useState(id === 'new');
    const [disabled, setDisabled] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [showLeavingGuard, setShowLeavingGuard] = useState(false);


    const [name, setName] = useState('');
    const [errName, setErrName] = useState('');

    const [parentTeam, setParentTeam] = useState(null);
    const [errParentTeam, setErrParentTeam] = useState('');

    const [leaders, setLeaders] = useState([]);
    const [errLeaders, setErrLeaders] = useState('');

    const [assistantLeaders, setAssistantLeaders] = useState([]);
    const [errAssistantLeaders, setErrAssistantLeaders] = useState('');

    const [street, setStreet] = useState('');
    const [errStreet, setErrStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [errStreetNumber, setErrStreetNumber] = useState('');
    const [zip, setZip] = useState('');
    const [errZip, setErrZip] = useState('');
    const [city, setCity] = useState('');
    const [errCity, setErrCity] = useState('');


    const sortPersonContainer = (options) => {
        return options.sort((a, b) => {
            if (a.person && b.person) {
                if (a.person.last_name < b.person.last_name)
                    return -1;
                if (a.person.last_name > b.person.last_name)
                    return 1;
                if (a.person.first_name < b.person.first_name)
                    return -1;
                if (a.person.first_name > b.person.first_name)
                    return 1;
            }
            return 0;
        });
    }


    const sortNameContainer = (options) => {
        return options.sort((a, b) => {
            if (a.name && b.name) {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name) {
                    return 1;
                }
            }
            return 0;
        })
    }


    const teamNameOptions = teamsNames
        ?.filter(teamName => !team?.id || team.id === 'new' || teamName.name !== team.name)
        ?.map(teamName => ({ ...teamName, key: teamName.id }))
        ?? [];

    const leaderOptions = employeesNames?.data
        ?.map(person => ({
            'id': person['employee_id'],
            'key': person['employee_id'],
            'person': { 'first_name': person.first_name, 'last_name': person.last_name }
        }))
        ?? [];

    const assistantLeaderOptions = team?.members
        ?.filter(assistantLeader => !leaders?.some(leader => leader.id === assistantLeader.id))
        ?.map(assistantLeader => ({ ...assistantLeader, key: assistantLeader.id }))
        ?? [];


    const members = team?.members?.slice() ?? [];


    const init = () => {
        setName(team.name);
        setParentTeam(team?.parent && team.parent.id !== team.id
            ? ({ ...team.parent, key: team.parent.id })
            : null
        );
        setLeaders(team?.leaders
            ?.map(leader => ({ ...leader, key: leader.id }))
            ?? []
        );
        setAssistantLeaders(team?.assistant_leaders
            ?.map(assistantLeader => ({ ...assistantLeader, key: assistantLeader.id }))
            ?? []
        );

        setStreet(team?.address?.street ?? '');
        setStreetNumber(team?.address?.street_number ?? '');
        setZip(team?.address?.zip ?? '');
        setCity(team?.address?.city ?? '');
    }


    useEffect(() => {
        if (team && team.id) {
            init();
        }
    }, [team]);


    const handleChange = (value, valueSetter, errorSetter) => {
        setIsChanged(true);
        valueSetter(value);
        errorSetter('');
    }


    const handleCancel = () => {
        if (isChanged) {
            setShowLeavingGuard(true);
            return;
        }

        setEditable(false);

        if (team && team.id) {
            init();
            return;
        }

        goToTeams();
    }


    const handleSave = () => {
        setDisabled(true);
        setIsChanged(false);

        let data = id ? { id: id } : {};

        data.name = name;
        data.parent_id = parentTeam?.id ?? null;
        data.leader_ids = leaders.map(leader => leader.id);
        data.assistant_leader_ids = assistantLeaders.map(leader => leader.id);

        if (street && streetNumber && zip && city) {
            data.street = street;
            data.street_number = streetNumber;
            data.zip = zip;
            data.city = city;
        }

        saveOrUpdateTeam(data).then((response) => {
            setDisabled(false);
            setEditable(false);

            if ((!data.id || data.id === 'new') && response.data.id) {
                goToTeam(response.data.id);
            } else {
                refetchTeam();
                refetchTeamsNames();
                refetchEmployeesNames();
            }
        }).catch((error) => {
            setDisabled(false);

            if (error.response?.status === 422 || error.response?.status === 500) {
                const errors = error.response.data?.errors;

                if (errors) {
                    setErrName(errors.name ?? '');
                    setErrParentTeam(errors.parent_id ?? '');
                    setErrLeaders(errors.leader_ids ?? '');
                    setErrAssistantLeaders(errors.leader_ids ?? '');
                    setErrStreet(errors.street ?? '');
                    setErrStreetNumber(errors.streetNumber ?? '');
                    setErrZip(errors.zip ?? '');
                    setErrCity(errors.city ?? '');
                }
            }
        });
    }


    const handleCancelLeavingGuard = () => {
        setShowLeavingGuard(false);
    }


    const handleConfirmLeavingGuard = () => {
        setShowLeavingGuard(false);
        setIsChanged(false);

        if (team && team.id) {
            setEditable(false);
            init();
        } else {
            goToTeams();
        }
    }


    const isLoading = isLoadingTeam && isLoadingTeamsNames && isLoadingEmployeesNames;


    return (
        <Box sx={{ padding: 2 }}>
            {isLoading ?
                <Stack sx={{ flexGrow: 1, padding: 2 }} alignItems="center">
                    <CircularProgress />
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                        <NavLink underline="hover" color="inherit" to="/teams">
                            <Trans i18nKey="teams.title">Teams</Trans>
                        </NavLink>
                        {team?.id ?
                            <Typography color="text.primary">{team.name}</Typography>
                            :
                            <Typography color="text.primary">
                                <Trans i18nKey="teams.new-title">New Team</Trans>
                            </Typography>
                        }
                    </Breadcrumbs>

                    <Paper elevation={0} sx={{ marginBottom: 2, padding: 2, maxWidth: 1024 }}>
                        <Grid container alignItems="center">

                            {/* Title */}
                            <Grid item xs={8}>
                                <Box sx={{ paddingLeft: 2, display: 'flex', justifyContent: 'flex-start' }}>
                                    {editable && team?.id &&
                                        <Typography variant="h5">
                                            <Trans i18nKey={"teams.edit-title"}>Edit team</Trans>
                                        </Typography>
                                    }
                                    {editable && !team?.id &&
                                        <Typography variant="h5">
                                            <Trans i18nKey={"teams.create-new-team"}>Create new team</Trans>
                                        </Typography>
                                    }
                                    {!editable &&
                                        <Typography variant="h5">{team?.name}</Typography>
                                    }
                                </Box>
                            </Grid>

                            {/* Edit Button */}
                            <Grid item xs={4} justifyContent="flex-end">
                                <AccessControl permissions={["teams.update", "teams"]}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {!editable &&
                                            <Button
                                                sx={{ textTransform: 'none' }}
                                                variant="outlined"
                                                size="small"
                                                disableElevation
                                                onClick={() => {
                                                    setEditable(!editable)
                                                }}
                                            >
                                                <Trans i18nKey="teams.edit">Edit</Trans>
                                            </Button>
                                        }
                                    </Box>
                                </AccessControl>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper elevation={0} sx={{ padding: 2, marginBottom: 2, maxWidth: 1024 }}>
                        <Grid container alignItems="center" >
                            <Grid item xs={12} sx={{ paddingLeft: 2 }}>
                                <Typography variant="h6">Teamdaten</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                <Trans i18nKey="teams.name.label">Name</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="name"
                                        fullWidth
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={name}
                                        placeholder={t('teams.name.placeholder', 'Enter Name')}
                                        InputProps={{ style: { fontSize: 14, backgroundColor: '#fff' } }}
                                        onChange={(event) => handleChange(event.target.value, setName, setErrName)}
                                        error={!!errName}
                                        helperText={errName}
                                    />
                                    :
                                    (team?.name ? team.name : '-')
                                }
                            </Grid>

                            <FormAutocomplete
                                value={parentTeam}
                                editable={editable}
                                onChange={(newValue) => {
                                    handleChange(newValue, setParentTeam, setErrParentTeam);
                                }}
                                i18nKeyLabel="teams.parent.label"
                                i18nKeyPlaceholder="teams.parent.placeholder"
                                options={sortNameContainer(teamNameOptions)}
                                getOptionLabel={(option) => option?.name ?? null}
                                valueText={parentTeam?.name && parentTeam?.name !== team?.name ? parentTeam?.name : null}
                                disabled={disabled}
                                showValidation={!!errParentTeam}
                                error={errParentTeam}
                            />

                            <FormAutocomplete
                                multiple
                                value={leaders}
                                editable={editable}
                                onChange={(newValue) => {
                                    handleChange(newValue, setLeaders, setErrLeaders);
                                    const newAssistantsLeaders = assistantLeaders.filter(
                                        assistantLeader => !newValue?.some(leader => leader.id === assistantLeader.id)
                                    );
                                    setAssistantLeaders(newAssistantsLeaders);
                                }}
                                i18nKeyLabel="teams.leader.label"
                                i18nKeyPlaceholder="teams.leader.placeholder"
                                options={sortPersonContainer(leaderOptions)}
                                getOptionLabel={(option) => option
                                    ? option.person.last_name + ' ' + option.person.first_name
                                    : '-'
                                }
                                valueText={leaders && leaders.length > 0
                                    ? leaders.filter(leader => leader?.person)
                                        .map(leader => `${leader.person.last_name} ${leader.person.first_name}`)
                                        .map(name => <span key={'leaders_' + name}>{name} <br /></span>)
                                    : '-'
                                }
                                disabled={disabled}
                                showValidation={!!errLeaders}
                                error={errLeaders}
                            />

                            {id !== 'new' &&
                                <FormAutocomplete
                                    multiple
                                    value={assistantLeaders}
                                    editable={editable}
                                    onChange={(newValue) => {
                                        handleChange(newValue, setAssistantLeaders, setErrAssistantLeaders);
                                    }}
                                    i18nKeyLabel="teams.assistant-leader.label"
                                    i18nKeyPlaceholder="teams.assistant-leader.placeholder"
                                    options={sortPersonContainer(assistantLeaderOptions)}
                                    getOptionLabel={(option) => option
                                        ? option.person.last_name + ' ' + option.person.first_name
                                        : '-'
                                    }
                                    valueText={assistantLeaders && assistantLeaders.length > 0
                                        ? assistantLeaders.filter(leader => leader?.person)
                                            .map(leader => `${leader.person.last_name} ${leader.person.first_name}`)
                                            .map(name => <span key={'assistant_leaders_' + name}>{name} <br /></span>)
                                        : '-'
                                    }
                                    disabled={disabled}
                                    showValidation={!!errAssistantLeaders}
                                    error={errAssistantLeaders}
                                />
                            }

                            {editable ? (
                                <>
                                    <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                        <Trans i18nKey="teams.street.label">Street</Trans>
                                    </Grid>

                                    <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                        <TextField
                                            disabled={disabled}
                                            id="street"
                                            fullWidth
                                            hiddenLabel
                                            size="small"
                                            variant="outlined"
                                            value={street}
                                            placeholder={t('teams.street.placeholder', 'Enter Street Name')}
                                            InputProps={{ style: { fontSize: 14, backgroundColor: '#fff' } }}
                                            onChange={(event) => handleChange(event.target.value, setStreet, setErrStreet)}
                                            error={!!errStreet}
                                            helperText={errStreet}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                        <Trans i18nKey="teams.street-number.label">Street Number</Trans>
                                    </Grid>

                                    <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                        <TextField
                                            disabled={disabled}
                                            id="house"
                                            sx={{ width: 215 }}
                                            hiddenLabel
                                            size="small"
                                            variant="outlined"
                                            value={streetNumber}
                                            placeholder={t('teams.street-number.placeholder', 'Enter House Number')}
                                            InputProps={{ style: { fontSize: 14, backgroundColor: '#fff' } }}
                                            onChange={(event) => handleChange(event.target.value, setStreetNumber, setErrStreetNumber)}
                                            error={!!errStreetNumber}
                                            helperText={errStreetNumber}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                        <Trans i18nKey="teams.zip.label">Zip Code</Trans>
                                    </Grid>

                                    <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                        <TextField
                                            disabled={disabled}
                                            id="zip"
                                            hiddenLabel
                                            size="small"
                                            variant="outlined"
                                            value={zip}
                                            sx={{ width: 215 }}
                                            placeholder={t('teams.zip.placeholder', 'Enter Zip Code')}
                                            InputProps={{ style: { fontSize: 14, backgroundColor: '#fff' } }}
                                            onChange={(event) => handleChange(event.target.value, setZip, setErrZip)}
                                            error={!!errZip}
                                            helperText={errZip}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                        <Trans i18nKey="teams.city.label">City</Trans>
                                    </Grid>

                                    <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                        <TextField
                                            disabled={disabled}
                                            id="city"
                                            fullWidth
                                            hiddenLabel
                                            size="small"
                                            variant="outlined"
                                            value={city}
                                            placeholder={t('teams.city.placeholder', 'Enter City Name')}
                                            InputProps={{ style: { fontSize: 14, backgroundColor: '#fff' } }}
                                            onChange={(event) => handleChange(event.target.value, setCity, setErrCity)}
                                            error={!!errCity}
                                            helperText={errCity}
                                        />
                                    </Grid></>)
                                : (
                                    <>
                                        <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                            <Trans i18nKey="teams.address.label">Address</Trans>
                                        </Grid>

                                        <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                            {team?.address
                                                ? team.address.street
                                                + " " + team.address.street_number
                                                + ", " + team.address.zip
                                                + " " + team.address.city
                                                : "-"
                                            }
                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>

                        {editable && (
                            <>
                                <Grid container maxWidth="1024px">
                                    <Grid item xs={12}>
                                        <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    marginRight: 2,
                                                    textTransform: 'none', background: '#fff'
                                                }}
                                                onClick={handleCancel}
                                            >
                                                <Trans i18nKey="teams.cancel">Cancel</Trans>
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                disableElevation
                                                sx={{ textTransform: 'none' }}
                                                onClick={handleSave}
                                            >
                                                <Trans i18nKey="teams.save">Save</Trans>
                                            </Button>

                                        </Box>
                                    </Grid>
                                </Grid>

                                <FormLeavingGuard
                                    when={isChanged}
                                    show={showLeavingGuard}
                                    onCancel={handleCancelLeavingGuard}
                                    onConfirm={handleConfirmLeavingGuard}
                                />
                            </>
                        )}
                    </Paper>

                    {!editable &&
                        <Paper elevation={0} sx={{ padding: 2, marginBottom: 2, maxWidth: 1024 }}>
                            <Grid container alignItems="center" >
                                <Grid item xs={12} sx={{ paddingLeft: 2 }}>
                                    <Typography variant="h6">Mitarbeiter</Typography>
                                </Grid>
                                <Box sx={{ height: '100%', width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                    {members?.length > 0
                                        ?
                                        <DataGrid
                                            rows={members}
                                            columns={[
                                                {
                                                    field: 'person',
                                                    headerName: t('teams.name.label', 'Name'),
                                                    valueGetter: (params) => {
                                                        const person = params?.value ?? null;
                                                        return person ? `${person.last_name} ${person.first_name}` : '-';
                                                    },
                                                    flex: 1,
                                                    editable: false,
                                                    headerClassName: 'data-grid-header',
                                                },
                                                {
                                                    field: 'client_count',
                                                    headerName: t('teams.clients.label', 'Clients'),
                                                    renderCell: (params) => {
                                                        params?.value ?? '-'
                                                    },
                                                    flex: 1,
                                                    editable: false,
                                                    headerClassName: 'data-grid-header',
                                                }
                                            ]}
                                            disableRowSelectionOnClick
                                            hideFooter
                                            disableColumnMenu
                                            sx={{
                                                '&.MuiDataGrid-root': {
                                                    border: '0 !important',
                                                },
                                                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                                    outline: 'none !important',
                                                },
                                                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                                    outline: 'none !important',
                                                },
                                                '& > .MuiDataGrid-columnSeparator': {
                                                    visibility: 'hidden',
                                                },
                                                '& .MuiDataGrid-columnHeaderTitle': {
                                                    fontWeight: 'bold'
                                                },
                                                '& .data-grid-header': {
                                                    '& > .MuiDataGrid-columnSeparator': {
                                                        visibility: 'hidden',
                                                    },
                                                },
                                                '& .data-grid-header:first-of-type': {
                                                    paddingLeft: 4
                                                },
                                                '& .MuiDataGrid-cell:first-of-type': {
                                                    paddingLeft: 4
                                                },
                                                '& .data-grid-header:last-of-type': {
                                                    paddingRight: 4
                                                },
                                                '& .MuiDataGrid-cell:last-of-type': {
                                                    paddingRight: 4
                                                },
                                            }}
                                        />
                                        :
                                        t('teams.member.none', 'No members')
                                    }
                                </Box>
                            </Grid>
                        </Paper>
                    }

                    {!editable &&
                        <Paper elevation={0} sx={{ padding: 2, marginBottom: 2, maxWidth: 1024 }}>
                            <Grid container alignItems="center" >
                                <Grid item xs={12} sx={{ paddingLeft: 2 }}>
                                    <Typography variant="h6">Ausgeliehene Mitarbeiter</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    }
                </>
            }
        </Box>
    )
};

export default Team;