import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const isSmallScreen = window.innerWidth < 600;

const EmployeesViewStore = (set, get) => ({
    columnVisibility: {
        name: true,
        email: true,
        phone: !isSmallScreen,
        roles: !isSmallScreen,
        team: !isSmallScreen,
    },

    sortModels: [], // element: { field, sort }
    filterModelItems: [], // element: { field, operator: "contains", value }

    paginationModel: {
        pageSize: 10,
        page: 0,
    },

    updateColumnVisibility: (inputColumnVisibility) => {
        const atLeastOneColumn = inputColumnVisibility?.name
            || inputColumnVisibility?.email
            || inputColumnVisibility?.phone
            || inputColumnVisibility?.roles
            || inputColumnVisibility?.team;

        set({
            columnVisibility: {
                name: (!atLeastOneColumn ? true : (inputColumnVisibility?.name ?? true)),
                email: (inputColumnVisibility?.email ?? true),
                phone: (inputColumnVisibility?.phone ?? true),
                roles: (inputColumnVisibility?.roles ?? true),
                team: (inputColumnVisibility?.team ?? true),
            }
        })
    },

    handleScreenSizeChange: (_) => {
        const currentVisibility = get().columnVisibility;

        const atLeastOneColumn = currentVisibility?.name
            || currentVisibility?.email
            || currentVisibility?.phone
            || currentVisibility?.roles
            || currentVisibility?.team;

        set({
            columnVisibility: {
                name: !atLeastOneColumn ? true : currentVisibility.name,
                email: currentVisibility.email,
                phone: currentVisibility.phone,
                roles: currentVisibility.roles,
                team: currentVisibility.team,
            }
        });
    },

    updateSortModels: (sortModels) => {
        set({
            sortModels: [...sortModels],
        });
    },

    updateFilterModelItems: (filterModel) => {
        set({
            filterModelItems: [...filterModel.items],
        });
    },

    updatePaginationModel: (paginationModel) => {
        set({
            paginationModel: {
                pageSize: parseInt(paginationModel?.pageSize) ?? 10,
                page: parseInt(paginationModel?.page) ?? 0,
            }
        })
    }

});

export const useEmployeesViewStore = create(
    persist(
        EmployeesViewStore,
        {
            name: 'employees-view-storage',
        }
    )
);
